<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'MainGroupbuyingsList',
              }"
              >總公司團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>下單數量設定</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row">
        <div class="col-12 col-xl-8">
          <h4 class="mb-2 font-weight-bold">下單數量設定</h4>

          <div class="mb-4">
            <b-form-group
              label-cols="12"
              content-cols="12"
              content-cols-lg="7"
              label-size="sm"
              label="請輸入商品編號，輸入完畢後，請按確認"
            >
              <div>
                <b-input-group>
                  <b-form-input
                    placeholder="請輸入商品編號"
                    v-model="code"
                    @keyup.enter="handleSearchProducts"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="!code" @click="handleSearchProducts"
                      >確認</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
                <ProductViewer
                  :loading="isFetchProduct"
                  :product="selectedProduct"
                />
              </div>
            </b-form-group>
          </div>

          <div v-if="selectedProduct">
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="商品可賣量"
              label-for="input-1"
            >
              <div>
                {{ salableQuantity }}
              </div>
            </b-form-group>

            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="每人最高購買數"
              label-for="once_max_quantity"
            >
              <b-form-input
                id="once_max_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                readonly="true"
                style="width: 80px"
                v-model="form.once_max_quantity"
                :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                :state="v$.form.once_max_quantity.$error ? false : null"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="每人最低購買數"
              label-for="once_quantity"
            >
              <b-form-input
                id="once_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 80px"
                v-model="form.once_quantity"
                :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                :state="v$.form.once_quantity.$error ? false : null"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="!v$.form.once_quantity.less.$invalid"
              >
                需小於每人最高購買數
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="下單數量遞增數"
              label-for="once_increase_quantity"
            >
              <b-form-input
                id="once_increase_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 80px"
                v-model="form.once_increase_quantity"
                :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                :state="v$.form.once_increase_quantity.$error ? false : null"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="!v$.form.once_quantity.less.$invalid"
              >
                『每人最低購買數+下單數量遞增數』不能高於『每人最高購買數』
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <template v-if="selectedProduct">
          <div class="col-6">
            <b-form-group class="items-center" label="">
              <b-form-radio-group
                id="radio-group-1"
                v-model="groupbuyingStatusFilterValue"
                name="radio-options"
                @change="getGroupbuyingByProduct"
              >
                <b-form-radio value="waiting">準備中</b-form-radio>
                <b-form-radio value="waiting|going"
                  >準備中 / 開團中</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </div>

          <div class="col-6">
            <div class="justify-content-md-center">
              <b-button
                class="mr-4"
                variant="outline-danger"
                :disabled="
                  isSubmmiting ||
                  groupbuyingStatusFilterValue !== 'waiting' ||
                  groupbuying.length === 0
                "
                @click="handleDeleteGroupbuy"
                >刪除</b-button
              >
              <b-button
                class="mr-4"
                variant="success"
                :disabled="!selectedProduct || isSubmmiting || total === 0"
                @click="handleUpdateCache"
                >更新商說頁快取</b-button
              >
              <b-button
                variant="success"
                :disabled="!selectedProduct || isSubmmiting || total === 0"
                @click="handleSubmitGroupbuy"
                >更新下單數量設定</b-button
              >
            </div>
          </div>

          <div class="col-12">
            <div>
              <label>共 {{ total }} 團</label>
            </div>
          </div>

          <div class="col-12">
            <b-table
              striped
              hover
              responsive
              :items="groupbuying"
              :fields="fields"
              :busy="isFetchGroupbuyList"
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #cell(groupbuying_number)="data">
                <router-link
                  :to="{
                    name: 'GroupbuyingsDetail',
                    params: {
                      groupbuyID: data.item.id,
                    },
                  }"
                  >{{ data.item.groupbuying_number }}
                </router-link>
              </template>

              <template #cell(status)="data">
                <div>{{ getStatusName(data.item.status) }}</div>
              </template>

              <template #cell(title)="data">
                <router-link
                  :to="{
                    name: 'MainGroupbuyingsDetail',
                    params: {
                      groupbuyID: data.item.id,
                    },
                  }"
                >
                  <div>
                    {{ data.item.products[0] && data.item.products[0].title }}
                  </div>
                  <div class="mb-1">
                    {{
                      data.item.products[0] &&
                      data.item.products[0].sale_page_code
                    }}
                  </div>
                </router-link>
              </template>

              <template #cell(picture)="data">
                <b-img-lazy
                  v-if="
                    data.item.products[0] &&
                    data.item.products[0].image_list &&
                    data.item.products[0].image_list[0].pic_url
                  "
                  class="thumb-image"
                  :src="
                    data.item.products[0] &&
                    data.item.products[0].image_list[0].pic_url
                  "
                  alt="商品圖片"
                  width="50"
                  height="50"
                />
              </template>

              <template #cell(branch_name)="data">
                <div>
                  {{ data.item.group.branch.name }}
                </div>
                <div class="mt-1">
                  {{ data.item.group.branch.branch_code }}
                </div>
              </template>
              <template #cell(group)="data">
                <div class="mb-1">
                  {{ data.item.group.name }}
                </div>
                <div>
                  {{ data.item.group.group_number }}
                </div>
              </template>

              <template #cell(date)="data">
                <div class="mb-1">
                  {{ format(new Date(data.item.start_at), "yyyy-MM-dd HH:mm") }}
                </div>
                <div>
                  {{ format(new Date(data.item.end_at), "yyyy-MM-dd HH:mm") }}
                </div>
              </template>
            </b-table>
          </div>
        </template>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { format, isBefore, set, isWithinInterval } from "date-fns";
import groupbuyApi from "@/apis/main-groupbuyings";
import { getStatus, statusMapping } from "@/pages/Dashboard/Groupbuyings/utils";
import ProductViewer from "@/components/Page/Dashboard/ProductViewer";

import { zh } from "vuejs-datepicker/dist/locale";

export default {
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  components: {
    ProductViewer,
  },

  data() {
    return {
      zh,
      format,
      code: "",
      isFetchProduct: false,
      isFetchGroupbuyList: false,
      isSubmmiting: false,
      selectedProduct: null,
      groupbuyingStatusFilterValue: "waiting",
      form: {
        once_max_quantity: 1, // 每人最高購買數
        once_quantity: 1, // 每人最低購買數
        once_increase_quantity: 1, // 下單數量遞增數
      },
      fields: [
        {
          key: "status",
          label: "團購狀態",
        },
        {
          key: "title",
          label: "商品名稱/編號",
        },
        {
          key: "picture",
          label: "商品圖片",
        },
        {
          key: "products[0].computed_unit_price",
          label: "單價",
          thClass: "text-center",
        },
        {
          key: "branch_name",
          label: "開團分店/編號",
          thClass: "text-center",
        },
        {
          key: "group",
          label: "群組名稱/編號",
          thClass: "text-center",
        },
        {
          key: "once_max_quantity",
          label: "每人最高購買數",
          thClass: "text-center",
        },
        {
          key: "once_quantity",
          label: "每人最低購買數",
          thClass: "text-center",
        },
        {
          key: "once_increase_quantity",
          label: "下單數量遞增數",
          thClass: "text-center",
          formatter: (value) => (value < 0 ? 1 : value),
        },
        {
          key: "date",
          label: "開團/結團時間",
        },
      ],
      total: 0,
      groupbuying: [],
    };
  },

  validations() {
    return {
      form: {
        once_max_quantity: {
          required,
        },
        once_quantity: {
          less: () => {
            return this.form.once_max_quantity
              ? Number(this.form.once_max_quantity) >=
                  Number(this.form.once_quantity)
              : true;
          },
        },
        once_increase_quantity: {
          less: () =>
            Number(this.form.once_quantity) +
              Number(this.form.once_increase_quantity) <=
            Number(this.form.once_max_quantity),
        },
      },
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("general", ["currentBranch"]),
    salableQuantity() {
      return this.selectedProduct?.sku_list?.[0]?.quantity;
    },
  },

  methods: {
    getStatusName(status) {
      return statusMapping[getStatus(status)];
    },
    async handleSearchProducts() {
      if (!this.code) {
        return;
      }
      try {
        this.isFetchProduct = true;
        const { data } = await groupbuyApi.getGroupBuyProducts(
          this.code,
          this.currentBranch.id
        );
        if (data.data.is_closed) {
          throw new Error("is_closed");
        }
        if (isBefore(new Date(data.data.available_end_at), new Date())) {
          throw new Error("available_end_at", {
            cause: data.data.available_end_at,
          });
        }

        this.selectedProduct = data.data;
        await this.getGroupbuyingByProduct();

        this.form.once_max_quantity =
          this.selectedProduct.sku_list[0]?.once_max_quantity ?? 1;
        this.form.once_quantity =
          this.selectedProduct.sku_list[0]?.once_quantity ?? 1;
        this.form.once_increase_quantity =
          this.selectedProduct.sku_list[0]?.once_increase_quantity ?? 1;
      } catch (error) {
        this.selectedProduct = null;
        let errorMessage;
        switch (error.message) {
          case "is_closed":
            errorMessage = `您輸入的商品頁編號：${this.code} <br/>商品已關閉`;
            break;
          case "available_end_at":
            errorMessage = `您輸入的商品頁編號：${
              this.code
            } <br/>商品已於 ${format(
              new Date(error.cause),
              "yyyy-MM-dd HH:mm"
            )} 結束銷售`;
            break;
          default:
            errorMessage = `您輸入的商品頁編號：${this.code} <br/>查無此商品`;
        }
        this.$swal({
          type: "error",
          html: errorMessage,
        }).then(() => {
          this.code = null;
        });
      }
      this.isFetchProduct = false;
    },

    async getGroupbuyingByProduct() {
      try {
        this.isFetchGroupbuyList = true;
        const { data } = await groupbuyApi.getGroupbuyingByProduct({
          productID: this.selectedProduct.id,
          groupbuyingStatus: this.groupbuyingStatusFilterValue.split("|"),
          perPage: 9999999, // get all
        });
        this.groupbuying = data.data;
        this.total = data.meta.total;
      } catch (error) {
        this.groupbuying = null;
      }

      this.isFetchGroupbuyList = false;
    },

    handleDeleteGroupbuy() {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async () => {
          try {
            this.isSubmmiting = true;
            await groupbuyApi.deleteGroupbuyingByProduct(
              this.selectedProduct.id,
              this.groupbuyingStatusFilterValue.split("|")
            );
            this.$swal
              .fire({
                icon: "success",
                text: "刪除完成",
              })
              .then(() => {
                this.getGroupbuyingByProduct();
              });
          } catch (error) {
            console.error(error);
          }
          this.isSubmmiting = false;
        });
    },

    validateProductTime() {
      if (!this.selectedProduct) return false;
      const groupbuyStartAt = new Date(
        format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy/MM/dd HH:mm:ss" // for safari data format compatible
        )
      );
      const groupbuyEndAt = new Date(
        format(
          set(new Date(this.form.end_at.date), {
            hours: Number(this.form.end_at.time.HH),
            minutes: Number(this.form.end_at.time.mm),
            seconds: 0,
          }),
          "yyyy/MM/dd HH:mm:ss"
        )
      );

      /**
       * 產品銷售時間 start <= 開團時間 start <= 產品銷售時間 end
       * 產品銷售時間 start <= 開團時間 end <= 產品銷售時間 end
       */
      return (
        isWithinInterval(groupbuyStartAt, {
          start: new Date(this.selectedProduct.available_start_at),
          end: new Date(this.selectedProduct.available_end_at),
        }) &&
        isWithinInterval(groupbuyEndAt, {
          start: new Date(this.selectedProduct.available_start_at),
          end: new Date(this.selectedProduct.available_end_at),
        })
      );
    },

    async handleSubmitGroupbuy() {
      const result = await this.v$.$validate();

      if (!result) return;

      this.$swal
        .fire({
          type: "warning",
          title: "你確定要修改嗎？",
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then((swalRes) => {
          if (!swalRes.value) {
            // 取消
            return;
          }
          this.handleUpdateGroupbuy();
        });
    },

    async handleUpdateGroupbuy() {
      try {
        this.isSubmmiting = true;
        await groupbuyApi.updateGroupbuyingByProduct(
          this.selectedProduct.id,
          this.groupbuyingStatusFilterValue.split("|"),
          this.form
        );
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            this.getGroupbuyingByProduct();
          });
      } catch (error) {
        console.error(error);
      }
      this.isSubmmiting = false;
    },

    // 更新快取
    async handleUpdateCache() {
      try {
        this.isSubmmiting = true;
        await groupbuyApi.clearGroupbuyingCacheByProduct(
          this.selectedProduct.id
        );
        this.$swal
          .fire({
            icon: "success",
            text: "已完成更新",
          })
          .then(() => {
            this.getGroupbuyingByProduct();
          });
      } catch (error) {
        console.error(error);
      }
      this.isSubmmiting = false;
    },
  },
};
</script>

<style scoped>
.form-row {
  align-items: center;
}

.custom-radio {
  align-items: center;
}
.table-responsive {
  min-height: auto;
}
</style>
